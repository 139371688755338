import '../../App.css'
import { useState, useEffect } from 'react';
import { Modal, Input, DatePicker, Table } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import qs from 'qs';
import { FaAngleDown } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa";
import axios from 'axios';
import Appconfig from '../../config/config'


function MarketAnalysis() {
    const location = useLocation();
    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userdata'))

    const [isOpen, setIsOpen] = useState(true)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [type, setType] = useState('settle')

    const [eventsName, setEventsName] = useState([])
    const [angles, setAngles] = useState([]);

    useEffect(() => {
        setAngles(eventsName.map(() => false));
    }, [eventsName]);


    const getOpenBetEvents = async () => {

        var data = JSON.stringify({
            user_id: userInfo?._id,
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}betting/getAuraMarketAnalysis`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log('response', response);
                if (response.data.result) {
                    setEventsName(response.data.resultData);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getOpenBetEvents()
    },[])
    const toggleAngle = (index, matchId) => {
        // console.log('index', index);
        // console.log('matchId', matchId);

        // setAngles((prevAngles) =>
        //     prevAngles.map((angle, i) => (i === index ? !angle : angle))
        // );

        // getOpenBetsByEvent(matchId);
    };

    const showAgeModal = () => {
        setIsOpen(true);
    };
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };
    let locale = {
        emptyText: 'No data!',
    };
    const columns = [
        {
            title: 'User Name',
            dataIndex: 'name',
            sorter: true,
            render: (name) => `${name.first} ${name.last}`,
        },
        {
            title: 'SportName',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Event',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Market',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Selection',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Type',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Odds Req.',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Stack',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Place Time',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Settle Time',
            dataIndex: 'name',
            sorter: false,
        },

    ];
    const getRandomuserParams = (params) => ({
        results: params.pagination?.pageSize,
        page: params.pagination?.current,
        ...params,
    });
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);


    return (
        <div className='grid grid-cols-12 relative mt-[20px] lg:mt-[15px]  px-[15px] lg:px-[30px]' >
            <div className='col-span-12 '>
                <div className='bg-[#fff] border rounded-[.375rem]'>
                    <div className='col-span-12 px-[6px] py-[5px] font-bold text-[0.875rem] rounded-[1px] h-[35px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex flex-shrink-0 items-center justify-between'>
                        <h5 className='text-[0.938rem] font-bold mb-0 leading-normal'>Sports</h5>
                    </div>
                    {/* {
                    eventsName?.map((events, i) => {
                        return (
                            <div className='p-3 border-b-[2px] border-b-[#ededed] flex justify-between cursor-pointer' onClick={() => toggleAngle(i, events.match_id)}>
                                <div className='text-[#2789ce] text-base font-semibold'>{events.event_name}</div>
                                {angles[i] ? (
                                    <FaAngleDown size={20} />
                                ) : (
                                    <FaAngleRight size={20} />
                                )}
                            </div>
                        )
                    })
                } */}   

                {
                    eventsName && eventsName.map((event) => (
                        <div className='p-1 border-b-[2px] border-b-[#ededed] flex justify-between cursor-pointer'
                        //  onClick={() => toggleAngle(i, events.match_id)}
                        onClick={() => navigate(`/market-details/4/${event?.match_id}`)}
                    >
                        <div className='text-[#2789ce] text-base font-semibold text-[13px]'>{event?.event_name}</div>
                        <div className='text-[#000] text-base font-semibold text-[13px]'>Total Bets {event?.total_bets}</div>
                    </div>
                    ))
                }

                   

                </div>

            </div>
        </div>
    )
}


export default MarketAnalysis;
