import '../../App.css'
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ChangePassword from '../../pages/change-password';
import Appconfig from '../../config/config'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';
import { Modal, Table, Input } from 'antd';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

function Dashboard() {

    const navigate = useNavigate();
    const userInfo = JSON.parse(localStorage.getItem('userdata'))
    console.log('userInfo', userInfo);

    const [user_nameValidationError, setUser_NameValidationError] = useState("Please enter old password");
    const [passwordValidationError, setPasswordValidationError] = useState("Please enter new password");
    const [conpasswordValidationError, setConPasswordValidationError] = useState("Please check your confirm password");


    const [formData, setformData] = useState({
        user_id: userInfo?._id,
        name: userInfo?.name,
        user_name: userInfo?.user_name,
        user_type: userInfo?.user_type,
        old_password: '',
        new_password: '',
        con_password: '',

    });

    function setOldPassword(value) {
        setformData({
            ...formData,
            old_password: value
        })
    }

    function setNewPassword(value) {
        setformData({
            ...formData,
            new_password: value
        })
    }

    function setConPassword(value) {
        setformData({
            ...formData,
            con_password: value
        })
    }
    function checkValidation() {

        if (formData.old_password.trim() === "") {

            setUser_NameValidationError('Please enter old password')
            // document.querySelector('.oldpassword-error').classList.remove('hidden');
            // console.log('hlw');

        }
        if (formData.new_password.trim() === "") {
            setPasswordValidationError('Please enter new password')
            // document.querySelector('.newpassword-error').classList.remove('hidden');
        }
        if (formData.con_password.trim() !== formData.new_password.trim()) {
            // document.querySelector('.conpassword-error').classList.remove('hidden');
            setConPasswordValidationError('Please check your confirm password')
        }

        if (formData.old_password !== "" && formData.new_password !== "" && formData.con_password !== "" && formData.new_password === formData.con_password) {
            return true
        }
        return false;

    }


    const changePassword = async () => {

        if (checkValidation()) {

            formData.master_id = userInfo?._id;
            formData.masters = userInfo?.masters;

            var config = {
                method: 'post',
                url: `${Appconfig.apiUrl}users/auraChangePassword`,
                headers: {
                    'Content-Type': 'application/json',

                },

                data: JSON.stringify(formData)

            };
            axios(config)
                .then(function (response) {

                    if (response.data.result) {
                        NotificationManager.success(response.data.message, '', 3000);
                        formData.old_password = '';
                        formData.new_password = '';
                        formData.con_password = '';

                        setTimeout(() => {
                            localStorage.removeItem('userdata');
                            localStorage.removeItem('login_token');
                            navigate('/login');
                        }, 3000)

                    } else {
                        NotificationManager.error(response.data.message, '', 3000);

                    }


                })
                .catch(function (error) {
                    console.log(error);
                });
        }


    }

    const options = {
        chart: {
            type: 'pie'
        },
        title: {
            text: 'Backup Sports Profit'
        },
       
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: [{
                    enabled: true,
                    distance: 20
                }, {
                    enabled: true,
                    distance: -40,
                    format: '{point.percentage:.1f}',
                    style: {
                        fontSize: '1.2em',
                        textOutline: 'none',
                        opacity: 0.7
                    },
                    filter: {
                        operator: '>',
                        property: 'percentage',
                        value: 10
                    }
                }]
            }
        },
        series: [
            {
                name: 'Number',
                colorByPoint: true,
                data: [
                    {
                        name: 'Soccer',
                        y: 135
                    },
                    {
                        name: 'Tennis',
                        sliced: true,
                        selected: true,
                        y: 100
                    },
                    {
                        name: 'Cricket',
                        y: 200
                    },
                    {
                        name: 'Casino',
                        y: 150.5
                    },
                ]
            }
        ]
    };


    return (
        <>
            <NotificationContainer />
            {
                userInfo &&
                userInfo?.is_password_update != "Yes" &&
                <Modal className='password-model relative top-1 lg:top-7 lg:!w-[34vw]'
                    footer={[
                        <div className='col-span-12 gap-2 flex flex-shrink-0 flex-wrap items-center justify-end p-[1rem] border-t-[1px] border-t-[#dee2e6]'>
                            <button type='submit ' className='text-white bg-[#1c272d] hover:!bg-transparent hover:text-inherit border-[1px] border-[#1c272d]   text-[.875rem] rounded-[.25rem] px-[10px] py-[5px]   '
                                onClick={() => changePassword()}
                            >
                                Confirm
                            </button>

                        </div>
                    ]}
                    mask={false} open={true} >
                    <div className='grid grid-cols-12'>
                        <div className='col-span-12 px-[6px] py-[5px] font-bold text-[0.875rem] rounded-t-[5px] h-[35px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] flex flex-shrink-0 items-center justify-between'>
                            <h5 className='text-[0.938rem] font-bold mb-0 leading-normal'>Change Password</h5>
                        </div>
                        <div className='col-span-12 !bg-[#ffffff] !text-[#23282c] relative flex-auto p-4'>
                            <div className='grid grid-cols-12 lg:gap-x-6 '>
                                <div className='col-span-12 lg:col-span-6'>
                                    <div className='mb-4 !text-[#23282c]'>
                                        <label className='inline-block mb-[.5rem] !text-[#23282c]' for="oldPassword">Old Password </label>
                                        <div className='relative flex flex-wrap items-stretch w-full text-[#23282c]'>
                                            <Input.Password id='oldPassword' name='oldPassword'
                                                onChange={(e) => setOldPassword(e.target.value)}
                                                required className='antd-password' placeholder="Old Password.." />
                                            <span className="absolute top-[25px] oldpassword-error hidden  text-[0.813rem] text-[red] leading-[14px]">
                                                <div className='leading-[38px] '>{user_nameValidationError}</div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-12 lg:col-span-6'>
                                    <div className='mb-4 !text-[#23282c]'>
                                        <label className='inline-block mb-[.5rem] !text-[#23282c]' for="newPassword">New Password  </label>
                                        <div className='relative flex flex-wrap items-stretch w-full text-[#23282c]'>
                                            <Input.Password id='newPassword' name='newPassword'
                                                onChange={(e) => setNewPassword(e.target.value)} required className='antd-password' pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&]{8,}$' placeholder="New Password.." />
                                            <span className="absolute top-[25px] newpassword-error hidden  text-[0.813rem] text-[red] leading-[14px]">
                                                <div className='leading-[38px] '>{passwordValidationError}</div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-12 lg:col-span-6'>
                                    <div className='mb-4 !text-[#23282c]'>
                                        <label className='inline-block mb-[.5rem] !text-[#23282c]' for="confirmPass">Confirm Password  </label>
                                        <div className='relative flex flex-wrap items-stretch w-full text-[#23282c]'>
                                            <Input.Password id='confirmPass' name='confirmPass'
                                                onChange={(e) => setConPassword(e.target.value)} required className='antd-password' placeholder="Confirm Password.." />
                                            <span className="absolute top-[25px] conpassword-error hidden  text-[0.813rem] text-[red] leading-[14px]">
                                                <div className='leading-[38px] '>{conpasswordValidationError}</div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>
            }

            <div className='grid grid-cols-12 relative mt-[20px] lg:mt-[15px] lg:px-[17px] ' >
                <div className='col-span-12 lg:col-span-6 px-[16px] lg:px-[13px]'>
                    <div className='mb-[1.5rem] w-full'>
                        <div className='font-bold text-[0.938rem] leading-[23px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] px-[10px] py-[5px] text-[#ffffff] rounded-tl-[calc(.25rem-1px)] rounded-br-[0] rounded-tr-[calc(.25rem-1px)] rounded-bl-[0]'>
                            Live Sports Profit
                        </div>
                        <div className='p-[0px flex-auto] w-full border-[1px] border-[#0000002d] bg-[#ffffff] flex items-center justify-center'>
                            <svg version="1.1" className="highcharts-root" xmlns="http://www.w3.org/2000/svg" width="398" height="400" viewBox="0 0 398 400" role="img" aria-label="">
                                <desc>Created with Highcharts 11.1.0</desc>
                                <defs>
                                    <filter id="highcharts-drop-shadow-1">
                                        <feDropShadow dx="1" dy="1" floodColor="#000000" floodOpacity="0.75" stdDeviation="2.5"></feDropShadow>
                                    </filter>
                                    <clipPath id="highcharts-io213m8-4-">
                                        <rect x="0" y="0" width="378" height="375" fill="none"></rect>
                                    </clipPath>
                                </defs>
                                <rect fill="#ffffff" className="highcharts-background" filter="none" x="0" y="0" width="398" height="400" rx="0" ry="0"></rect>
                                <rect fill="none" className="highcharts-plot-background" x="10" y="10" width="378" height="375" filter="url(#highcharts-drop-shadow-1)"></rect>
                                <rect fill="none" className="highcharts-plot-border" data-z-index="1" stroke="#cccccc" strokeWidth="0" x="10" y="10" width="378" height="375"></rect>
                                <g className="highcharts-series-group" data-z-index="3" filter="none">
                                    <g className="highcharts-series highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="0.1" opacity="1" transform="translate(10,10) scale(1 1)" filter="none" clipPath="none">
                                        <path fill="none" d="M 189 10 A 177.5 177.5 0 1 1 188.82250002958324 10.000088749992614 M 189 187.5 A 0 0 0 1 0 189 187.5" className="highcharts-empty-series" strokeWidth="1" stroke="#cccccc"></path>
                                    </g>
                                    <g className="highcharts-markers highcharts-series-0 highcharts-pie-series" data-z-index="0.1" opacity="1" transform="translate(10,10) scale(1 1)" clipPath="none"></g>
                                </g>
                                <text x="199" textAnchor="middle" className="highcharts-title" data-z-index="4" y="25"></text>
                                <text x="199" textAnchor="middle" className="highcharts-subtitle" data-z-index="4" y="24"></text>
                                <text x="10" textAnchor="start" className="highcharts-caption" data-z-index="4" y="397"></text>
                                <g className="highcharts-data-labels highcharts-series-0 highcharts-pie-series highcharts-tracker" data-z-index="6" opacity="1" transform="translate(10,10) scale(1 1)"></g>
                                <g className="highcharts-legend highcharts-no-tooltip" data-z-index="7" visibility="hidden">
                                    <rect fill="none" className="highcharts-legend-box" rx="0" ry="0" stroke="#999999" strokeWidth="0" filter="none" x="0" y="0" width="8" height="8"></rect>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className='col-span-12 lg:col-span-6 px-[16px] lg:px-[13px]  '>
                    <div className='mb-[1.5rem] w-full'>
                        <div className='font-bold text-[0.938rem] leading-[23px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] px-[10px] py-[5px] text-[#ffffff] rounded-tl-[calc(.25rem-1px)] rounded-br-[0] rounded-tr-[calc(.25rem-1px)] rounded-bl-[0]'>
                            Backup Sports Profit
                        </div>
                        <div className='p-[0px flex-auto] w-full border-[1px] border-[#0000002d] bg-[#ffffff] flex items-center justify-center'>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                            />
                        </div>
                    </div>
                </div>

            </div >


        </>
    );
}

export default Dashboard;
